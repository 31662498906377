import React from 'react';
import './App.css';
import { useForm } from "react-hook-form";
import axios from 'axios';
import leaves from './leaves.jpg'

interface FormData {
  address?: string;
  name?: string;
  comments?: string;
  phone?: string;
  email?: string;
}

function App() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  React.useEffect(() => {
    
  }, )

  const sendEmailDataToBackend = async (data: FormData) => {
    const { address, name, comments, phone, email } = data;
    
    const result = await axios.post(
      process.env.NODE_ENV === 'production' 
        ? 'https://leavesapi.thedyckfamily.com/.netlify/functions/leafing-request'
        : 'http://localhost:8888/.netlify/functions/leafing-request​',
        JSON.stringify({ address, name, comments, phone, email })
    );

    // const result = await axios.post(
    //   'http://localhost:8888/.netlify/functions/leafing-request/',
    //   JSON.stringify({ address, name, comments, phone, email })
    // );

    if (result.status === 200) {
      alert('Success! We got your message.')
    } else {
      alert('Sorry, it looks like there was a problem getting your request.')
    }
  }

  if (window.location.pathname === '/' || window.location.pathname === '/leaves') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: `url(${leaves})`,  backgroundSize: 'cover' }} className="App">
        <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', maxWidth: 660, borderRadius: 30, padding: '40px 60px' }}>
          <p style={{ fontWeight: 600, fontSize: 40, margin: 0 }}>WE WANT YOUR</p>
          <h1 style={{ fontWeight: 600, fontSize: 70, marginTop: 0, marginBottom: 0, letterSpacing: 3.9 }}>LEAVES!</h1>
          <br />
          <div style={{ textAlign: 'left' }}>
            <h3>Howdy Neighbours!</h3>
            <p>
              We are looking to collect a lot of leaves this fall and were hoping for some <strong style={{ textDecoration: 'underline'}}>generous</strong> neighbours to let our kids rake their leaves routinely throughout the fall season. <br/>
            </p>
            
            <br />
            <p><strong>Where will we be raking?</strong></p>
            <p>
              We looking to have a few neighbours around the Grange Avenue area whose <em>front yard</em> (no back yards, sorry) can be raked on a routine basis.
            </p>
            <br />
            <p><strong>Sounds good?</strong></p>
            <p>If that is you, then please send us a note on the form and if you have any questions we can get back to you via email or phone.</p>
          </div>

          <form
            onSubmit={
              handleSubmit(sendEmailDataToBackend)
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
            >
              <p>Your Address</p>
              <input {...register("address", { required: true })} placeholder="Street Address" />
              {errors.address && errors.address.type === "required" && <span>Street address is required</span>}

              <p>Your Name</p>
              <input {...register("name", { required: true })} placeholder="Full Name" />
              {errors.name && errors.name.type === "required" && <span>Name is required</span>}

              <p>Your Email (optional)</p>
              <input {...register("email")} placeholder="generous.neighbour@gmail.com" />
              <p>Your Phone (optional)</p>
              <input {...register("phone")} placeholder="Phone" />

              <p>Any Questions? (optional)</p>
              <textarea {...register("comments")} placeholder="Comments/Questions" />
            </div>
            <input type="submit" />
          </form>
        </div>
      </div>
    );  
  } else {
    return <div>404 - That page does not exist...</div>
  }
}

export default App;
